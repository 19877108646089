import { PageContainer, useLazyHydration } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import React, { useState, useRef, useCallback, useEffect } from 'react';
import { SxStyleProp } from 'theme-ui';

import { MegaMenuButton } from './MegaMenuButton';
import { MegaMenuContent } from './MegaMenuContent';
import { headerStyles } from '../variants/standard/headerStyles';
import { MegaMenuItem } from '@AuroraTypes';
import { use100vh } from '@Core/use100vh';

function getScrollBarGap() {
  if (typeof window === 'undefined') {
    return 0;
  }

  return window.innerWidth - document.documentElement.clientWidth;
}

const backgroundStyles = (
  isOpen: boolean,
  scrollBarGap: number,
  innerHeight: string,
): SxStyleProp => ({
  position: 'fixed',
  top: 0,
  left: 0,
  backgroundColor: 'megaMenuBackground',
  height: isOpen ? innerHeight : headerStyles.height,
  width: '100vw',
  opacity: isOpen ? '1' : '0',
  visibility: isOpen ? 'visible' : 'hidden',
  transitionProperty: 'height, opacity',
  transitionDuration: '.3s',
  zIndex: 'megaMenu',
  paddingRight: scrollBarGap,
  // fixes menu content hidden in some cases on chrome
  backfaceVisibility: 'hidden',
  overflow: 'auto',
  textAlign: 'left',
});

const megaMenuListId = 'mega-menu-list';

interface MegaMenuProps {
  items: MegaMenuItem[];
  opened?: boolean;
}

export const MegaMenu: React.FC<MegaMenuProps> = ({ items, opened = false }) => {
  const { t } = useTranslation();

  const megaMenuRef = useRef<HTMLDivElement>(null);
  const openButtonRef = useRef<HTMLButtonElement>(null);
  const closeButtonRef = useRef<HTMLButtonElement>(null);
  const [isOpen, setOpen] = useState(opened);
  const [setHydrated, LazyHydrateWrapper] = useLazyHydration();
  const innerHeight = use100vh();

  const toggleMenu = useCallback(() => {
    setHydrated();
    setOpen((state) => {
      if (state && openButtonRef.current) {
        openButtonRef.current.focus();
      }

      if (!state && closeButtonRef.current) {
        closeButtonRef.current.focus();
      }

      return !state;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isOpen && megaMenuRef.current) {
      disableBodyScroll(megaMenuRef.current, {
        reserveScrollBarGap: true,
      });
    } else {
      enableBodyScroll(megaMenuRef.current);
    }

    return () => clearAllBodyScrollLocks();
  }, [isOpen]);

  return (
    <nav aria-label="page navigation">
      <MegaMenuButton
        data-id="open-mega-menu-button"
        ref={openButtonRef}
        aria-controls={megaMenuListId}
        aria-label={t('header.openMenu')}
        isOpen={isOpen}
        onClick={toggleMenu}
      />
      <div
        ref={megaMenuRef}
        sx={backgroundStyles(isOpen, getScrollBarGap(), innerHeight)}
      >
        <div
          sx={{
            position: 'sticky',
            top: 0,
            backgroundColor: 'megaMenuBackground',
            width: '100%',
            zIndex: 'megaMenuHeader',
          }}
        >
          <PageContainer
            sx={{
              display: 'flex',
              height: headerStyles.height,
              paddingY: headerStyles.paddingY,
            }}
          >
            <MegaMenuButton
              data-id="close-mega-menu-button"
              ref={closeButtonRef}
              aria-controls={megaMenuListId}
              aria-label="Mega Menu"
              isOpen={isOpen}
              onClick={toggleMenu}
            />
          </PageContainer>
        </div>

        <LazyHydrateWrapper>
          <MegaMenuContent
            id={megaMenuListId}
            items={items}
          />
        </LazyHydrateWrapper>
      </div>
    </nav>
  );
};
