import { Link, Logos } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React from 'react';

import { Brand } from '@AuroraTypes';
import { ClassNameProps } from '@ComponentProps';
import { useAppContext } from '@Contexts/contexts';
import { DesignSystemAsset } from '@DesignSystemComponents/DesignSystemAsset';

const brandLogos: Partial<Record<Brand, Logos>> = {
  loveholidays: 'Loveholidays/LargeColouredLight_New',
  lovevacations: 'Lovevacations/LargeColouredLight',
  weloveholidays: 'weLoveholidays/LargeColouredLight_New',
};

const brandLogosWidths: Partial<Record<Brand, [number, number, number]>> = {
  loveholidays: [140, 180, 210],
  lovevacations: [94.18, 121.09, 161.45],
  weloveholidays: [168, 216, 252],
};

export const Logo: React.FC<ClassNameProps> = ({ className }) => {
  const {
    site: { brand },
  } = useAppContext();
  const { t } = useTranslation();

  return (
    <Link
      href="/"
      title={t('home')}
      id="logo"
      className={className}
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <DesignSystemAsset
        type="Logo"
        name={brandLogos[brand]!}
        height={['28', '36', '48']}
        svgStyles={{
          width: brandLogosWidths[brand],
        }}
      />
    </Link>
  );
};
