import { AccordionToggle } from './AccordionToggle';
import { SelectableCollapse } from '@UX/Collapse/Collapse';
import { Selection } from '@UX/Selectable/Selection';

type AccordionType = typeof Selection & {
  Toggle: typeof AccordionToggle;
  Collapse: typeof SelectableCollapse;
};

const BaseAccordion = Selection as AccordionType;

BaseAccordion.Toggle = AccordionToggle;
BaseAccordion.Collapse = SelectableCollapse;

export const Accordion = BaseAccordion;
