import { Icon, keyboardOutline } from '@loveholidays/design-system';
import React, { forwardRef } from 'react';
import { SxStyleProp } from 'theme-ui';

import { HTMLButtonProps, ClassNameProps } from '@ComponentProps';

const buttonSize = 50;

const buttonStyles: SxStyleProp = {
  color: 'megaMenuButton',
  width: buttonSize,
  height: buttonSize,
  borderRadius: 3,
  '&:hover': {
    backgroundColor: 'megaMenuButtonHoverBackground',
  },
  position: ['absolute', null, 'static'],
  top: [0, '3xs'],
  right: '2xs',
  marginLeft: 'auto',
  lineHeight: 0,
};

type Props = {
  isOpen: boolean;
} & ClassNameProps &
  HTMLButtonProps;

export const MegaMenuButton = forwardRef<HTMLButtonElement, Props>(
  ({ isOpen, className, ...props }, ref) => (
    <button
      type="button"
      aria-expanded={isOpen}
      aria-haspopup="true"
      className={className}
      {...props}
      ref={ref}
      sx={{
        ...buttonStyles,
        ...keyboardOutline,
      }}
    >
      <Icon
        name={isOpen ? 'Navigation/Close' : 'Navigation/Menu'}
        size="28"
      />
    </button>
  ),
);
