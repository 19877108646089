import { Icon, Link } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React from 'react';

import { ClassNameProps } from '@ComponentProps';
import { usePathTemplate } from '@Core/usePathTemplate';

export const FavouritesNavigation: React.FC<ClassNameProps> = ({ className }) => {
  const { t } = useTranslation();

  const favouritesPath = usePathTemplate('favourites');

  return (
    <Link
      aria-label={t('favourites.yourFavourites')}
      className={className}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        top: [0, null],
        height: '50px',
        width: '50px',
        marginLeft: ['auto', 'auto', 0],
        borderRadius: 3,
        '&:hover': {
          backgroundColor: 'megaMenuButtonHoverBackground',
        },
      }}
      href={favouritesPath}
      asAnchor={false}
      internal
    >
      <div
        sx={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Icon
          name="Toggles/FavoriteOff"
          size={['28', '36']}
          sx={{
            color: 'white',
          }}
        />
      </div>
    </Link>
  );
};
