import { Label, Link } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React from 'react';

import { ClassNameProps } from '@ComponentProps';
import { useFeatureFlag } from '@Core/octopus/useFeatureFlag';

interface CheckoutTelephoneProps extends ClassNameProps {
  telephoneNumber: string;
}

export const CheckoutTelephone: React.FC<CheckoutTelephoneProps> = ({
  telephoneNumber,
  className,
}) => {
  const { t } = useTranslation();
  const showPhoneOnMobile = useFeatureFlag('CheckoutPhoneMobile');

  return (
    <div
      className={className}
      sx={{
        display: showPhoneOnMobile ? 'flex' : ['none', null, 'flex'],
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        maxWidth: '165px',
        whiteSpace: 'pre-line',
        ...(showPhoneOnMobile && {
          position: ['absolute', null, 'static'],
          right: 's',
          textAlign: 'right',
        }),
      }}
    >
      <Link
        href={`tel:${telephoneNumber}`}
        trackingAction="checkout-phonenumber"
        sx={{
          display: showPhoneOnMobile ? ['none', 'initial'] : 'initial',
          '&, &:visited': {
            color: 'usp',
            textDecoration: 'none',
          },

          '&:hover': {
            color: 'usp',
            textDecoration: 'underline',
            textDecorationThickness: '1px',
          },
        }}
      >
        <Label
          variant={['small', 'medium']}
          sx={{
            color: 'usp',
          }}
        >
          {t('checkout.newBookings')}
          <br />
          {telephoneNumber}
        </Label>
      </Link>
      {showPhoneOnMobile && (
        <Link
          href={`tel:${telephoneNumber}`}
          trackingAction="checkout-phonenumber-mobile"
          sx={{
            display: ['initial', 'none'],
            '&, &:visited': {
              color: 'usp',
              textDecoration: 'none',
            },

            '&:hover': {
              color: 'usp',
              textDecoration: 'underline',
              textDecorationThickness: '1px',
            },
          }}
        >
          <Label
            variant={['small', 'medium']}
            sx={{
              color: 'usp',
            }}
          >
            {t('checkout.newBookings')}
            <br />
            {telephoneNumber}
          </Label>
        </Link>
      )}
    </div>
  );
};
