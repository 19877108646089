import React from 'react';

import { HTMLButtonProps } from '@ComponentProps';
import { ToggleSelection, ToggleSelectionProps } from '@UX/Selectable/ToggleSelection';

type AccordionToggleProps = HTMLButtonProps & ToggleSelectionProps;

export const AccordionToggle: React.FC<AccordionToggleProps> = ({
  children,
  controls,
  onClick,
  ...props
}) => (
  <ToggleSelection
    as="button"
    type="button"
    controls={controls}
    expands
    sx={{
      display: 'block',
    }}
    {...props}
  >
    {children}
  </ToggleSelection>
);
