import { PageContainer } from '@loveholidays/design-system';
import React, { memo, useMemo } from 'react';
import { useQuery } from 'urql';

import { AccountNavigation } from './AccountNavigation';
import { CheckoutTelephone } from './CheckoutTelephone';
import { FavouritesNavigation } from './FavouritesNavigation';
import getHeaderData from './getHeaderData.gql';
import { headerStyles } from './headerStyles';
import { Logo } from './Logo';
import { Query } from '@AuroraTypes';
import { usePageLayoutContext } from '@Client/context/PageLayoutContext';
import { MegaMenu } from '@Components/Header/MegaMenu/MegaMenu';
import { Usps } from '@Components/Header/Usps/Usps';
import { isQueryResolved } from '@Core/isQueryResolved';
import { pageContainerWidthOnDesktop } from '@UX/Page/LegacyPageContainer';

const Header: React.FC = memo(() => {
  const { headerConfig } = usePageLayoutContext();

  const {
    displayAccount,
    displayLogo,
    displayUsps,
    displayFavorite,
    displayMenu,
    displayPhone,
    displayHeader,
    headerComponents,
  } = headerConfig;

  if (!displayHeader) {
    return null;
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [{ data, fetching }] = useQuery<Query>({
    query: getHeaderData,
  });

  if (!isQueryResolved<Query>(fetching, data)) {
    return null;
  }

  const { megaMenu, usps, telephoneNumber } = data.Content.page.header;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useMemo(
    () => (
      <header
        sx={headerStyles}
        data-id="header"
      >
        <PageContainer
          sx={{
            display: 'flex',
            justifyContent: [
              'flex-start',
              null,
              headerComponents.length === 1 && displayHeader ? 'center' : 'space-between',
            ],
            alignItems: 'center',
            width: ['calc(100% - 70px)', 'calc(100% - 100px)', pageContainerWidthOnDesktop],
          }}
        >
          {displayLogo && <Logo />}
          {displayUsps && usps && <Usps usps={usps} />}
          {displayFavorite && <FavouritesNavigation />}
          {displayAccount && <AccountNavigation />}
          {displayMenu && megaMenu && <MegaMenu items={megaMenu} />}
          {displayPhone && telephoneNumber && (
            <CheckoutTelephone telephoneNumber={telephoneNumber} />
          )}
        </PageContainer>
      </header>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data.Content.page.header, headerConfig],
  );
});

export default Header;
