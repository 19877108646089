import { Link } from '@loveholidays/design-system';
import React from 'react';

import { MegaMenuItem } from '@AuroraTypes';
import { HTMLElementProps } from '@ComponentProps';

const styles = () => [
  // Level 1
  {
    titleStyles: {
      display: 'block',
      fontSize: 'xl',
      paddingBottom: 'xs',
      fontWeight: 'bold',
    },
  },
  // Level 2
  {
    titleStyles: {
      display: 'block',
      fontSize: ['m', 'l'],
      marginBottom: ['4xs', '3xs'],
      fontWeight: 'bold',
    },
    listItemStyles: {
      verticalAlign: 'top',
      paddingBottom: 'xs',
    },
    largeListItemStyles: {
      display: 'inline-block',
      width: ['50%', '25%'],
      verticalAlign: 'top',
    },
  },
  // Level 3
  {
    titleStyles: {
      fontWeight: 'normal',
      fontSize: 'm',
    },
    listStyles: {
      display: ['initial', 'flex'],
    },
    listItemStyles: {
      display: 'block',
      width: '100%',
      maxWidth: ['initial', '25%'],
    },
  },
  // Level 4
  {
    listStyles: {
      listStyle: 'disc',
      marginBottom: 'xs',
      marginLeft: 'xl',
    },
    listItemStyles: {
      h3: {
        display: 'inline',
        fontWeight: 'normal',
      },
    },
    titleStyles: {
      fontWeight: 'normal',
    },
  },
  // Level 5 - sub children
  {
    titleStyles: {
      display: 'block',
      fontSize: ['m', 'l'],
      marginBottom: ['4xs', '3xs'],
      fontWeight: 'bold',
    },
    listStyles: {
      display: [null, 'flex'],
      flexDirection: 'row',
      justifyContent: 'left',
      width: '100%',
    },
    listItemStyles: {
      width: ['100%', '50%', '33%'],
      verticalAlign: 'top',
      paddingBottom: 'xs',
    },
  },
  {
    listItemStyles: {
      display: 'inline-block',
      width: '50%',
    },
    titleStyles: {
      fontWeight: 'normal',
    },
  },
];

interface MegaMenuSectionProps extends HTMLElementProps {
  items: MegaMenuItem[];
  level?: number;
}

export const MegaMenuSection: React.FC<MegaMenuSectionProps> = ({ items, level = 0, ...props }) => {
  const {
    listStyles = {},
    titleStyles = {},
    listItemStyles = {},
    largeListItemStyles = {},
  } = styles()[level] || {};

  return (
    <ul
      sx={{
        ...listStyles,
        a: {
          '&, &:visited': {
            textDecoration: 'none',
          },
          ':hover': {
            textDecoration: 'underline',
          },
        },
      }}
      {...props}
    >
      {items.map((item, i) => {
        const { label, url, children } = item;
        const hasChildren = !!children?.length;
        const title = url ? (
          <Link
            href={url}
            sx={titleStyles}
          >
            {label}
          </Link>
        ) : (
          <strong sx={titleStyles}>{label}</strong>
        );

        return (
          <li
            key={i}
            sx={!hasChildren && level === 1 ? largeListItemStyles : listItemStyles}
          >
            {title}
            {hasChildren && (
              <MegaMenuSection
                items={children || []}
                level={level + 1}
              />
            )}
          </li>
        );
      })}
    </ul>
  );
};
