import { Illustrations, Link, LinkProps } from '@loveholidays/design-system';
import React, { ReactElement, Fragment } from 'react';

import { Usp as UspType } from '@AuroraTypes';
import { Illustration } from '@DesignSystemComponents/Illustration';
import { Text } from '@UX/Text/Text';

interface UspProps extends Omit<UspType, 'illustration'>, Pick<LinkProps, 'internal'> {
  illustration: ReactElement;
}

const Usp: React.FC<UspProps> = ({ title, link, illustration, internal = false }) => (
  <div
    sx={{
      paddingLeft: 'xs',
      paddingRight: 'xs',
      color: 'usp',
      display: 'flex',
      maxWidth: '200',
      alignItems: 'center',
      lineHeight: 1,
    }}
  >
    {illustration || null}
    {!!title && (
      <Text
        as="span"
        variant="small"
        sx={{
          textAlign: 'left',
          whiteSpace: 'pre-line',
        }}
      >
        {link ? (
          <Link
            href={link.href}
            internal={internal}
            rel={link.rel?.join(' ')}
            target={link.target}
            sx={{
              '&, &:visited': {
                color: 'usp',
                textDecoration: 'none',
              },

              '&:hover': {
                color: 'usp',
                textDecoration: 'underline',
              },
            }}
          >
            {title}
          </Link>
        ) : (
          <Fragment>{title}</Fragment>
        )}
      </Text>
    )}
  </div>
);

interface UspsProps {
  usps?: UspType[];
}

export const Usps: React.FC<UspsProps> = ({ usps = [] }) => {
  if (!usps.length) {
    return null;
  }

  return (
    <div
      sx={{
        display: ['none', null, 'flex'],
        flexDirection: 'row',
        alignItems: 'center',
        marginLeft: 'auto',
        marginRight: 's',
        justifyContent: 'flex-end',
      }}
    >
      {usps.map(({ illustration, ...item }) => (
        <Usp
          key={item.id}
          {...item}
          illustration={
            <Illustration
              name={illustration as unknown as Illustrations}
              size="36"
              sx={{
                marginRight: '3xs',
                minWidth: '36px',
              }}
            />
          }
        />
      ))}
    </div>
  );
};
